import i18n from '@/plugins/i18n'

export default [
    {
        _name: 'CSidebarNav',
        _children: [
            {
                _name: 'CSidebarNavItem',
                name: i18n.t('menu.dashboard'),
                to: '/dashboard',
                icon: 'cil-speedometer',                
                roles: ['admin', 'agent', 'sale', 'operator', 'investor', 'supervisor']
            },
            //Products
            {
                _name: 'CSidebarNavTitle',
                _children: [i18n.t('menu.products')],
                roles: ['admin']
            },
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.productManagement'),
                route: '/products',
                icon: 'cil-puzzle',
                roles: ['admin', 'operator'],
                items: [
                    {
                        name: i18n.t('menu.categories'),
                        to: '/products/categories'
                    },
                    {
                        name: i18n.t('menu.addCategory'),
                        to: '/products/categoryadd'
                    },                    
                    {
                        name: i18n.t('menu.productList'),
                        to: '/products/list'
                    },
                    {
                        name: i18n.t('menu.addProduct'),
                        to: '/products/productadd'
                    },
                    {
                        name: i18n.t('menu.units'),
                        to: '/units/list'
                    },
                ]
            },            
            // ware house       
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.warehouseManagement'),
                route: '/warehouses',
                icon: 'cil-puzzle',
                roles: ['admin', 'operator', 'investor', 'supervisor', 'sale', 'agent'],
                items: [
                    {
                        // quan ly xuat hang
                        roles: ['admin', 'operator'],
                        name: i18n.t('menu.transferInventoryList'),
                        to: '/warehouses/transferlist',
                    },
                    {
                        // quan ly nhap hang
                        roles: ['sale', 'agent'],
                        name: i18n.t('menu.receivedInventoryList'),
                        to: '/warehouses/receivelist'
                    },                                  
                    {
                        // ton kho
                        name: i18n.t('menu.inventoryManagement'),
                        to: '/warehouses/inventorylist'
                    },
                    {
                        // xe le ra ban
                        roles: ['agent'],
                        name: i18n.t('menu.retailinventoryadd'),
                        to: '/warehouses/retailinventoryadd',                        
                    },
                    {
                        // don dat hang si
                        roles: ['sale', 'agent'],
                        name: i18n.t('menu.agentBuyProduct'),                        
                        to: '/warehouses/adddealerorders'
                    },
                    {
                        // kho tong
                        roles: ['admin', 'operator'],
                        name: i18n.t('menu.warehouseImportManagement'),
                        to: '/warehouses/list'
                    },
                ]
            },            
            //Orders
            {
                _name: 'CSidebarNavTitle',
                _children: [i18n.t('menu.orders')]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.retailOrderManagement'),
                route: '/orders',
                icon: 'cil-puzzle',
                roles: ['admin', 'agent'],
                items: [
                    {
                        name: i18n.t('menu.orders'),
                        to: '/orders/list'
                    }
                ]
            },            
            //USERS
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.accountManagement'),
                route: '/users',
                icon: 'cil-puzzle',
                roles: ['admin', 'supervisor', 'sale'],
                items: [
                    {
                        name: i18n.t('menu.manageUsers'),
                        to: '/users/list'
                    },
                    {
                        name: i18n.t('menu.addUser'),
                        to: '/users/add'
                    },        
                    {
                        roles: ['admin'],
                        name: i18n.t('menu.manageRoles'),
                        to: '/roles/management'
                    },                             
                ]
            },
            //AGENTS
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.agentManagement'),
                route: '/users',
                icon: 'cil-puzzle',                
                roles: ['admin', 'sale'],
                items: [
                    {
                        name: i18n.t('menu.agents'),
                        to: '/agents/list'
                    },                    
                    {
                        roles: ['sale'],
                        name: i18n.t('menu.addAgent'),
                        to: '/agents/add'
                    },                                                     
                ]
            },            
            //Shareholders and shares
            //{
            //    _name: 'CSidebarNavDropdown',
            //    name: i18n.t('menu.sharesManagement'),
            //    icon: 'cil-puzzle',
            //    roles: ['admin'],
            //    items: [
            //        {
            //           // roles: ['admin'],
            //            name: i18n.t('menu.shareholdersManagement'),
            //            to: '/shareholders/list'
            //        },
            //        {
            //            //roles: ['admin'],
            //            name: i18n.t('menu.sharesManagement'),
            //            to: '/shares/listadmin'
            //        }
            //    ]
            //},
            // deposit and withdrawal
            //{
            //    _name: 'CSidebarNavDropdown',
            //    name: i18n.t('menu.depositAndWithdrawal'),
            //    route: '/assets',
            //    icon: 'cil-puzzle',
            //    //roles: ['operator', 'investor', 'supervisor', 'sale', 'agent'],
            //    roles: ['admin'],
            //    items: [
            //        {
            //            name: i18n.t('menu.depositManagement'),
            //            to: '/assets/deposits'
            //        },
            //        {
            //            name: i18n.t('menu.withdrawalManagement'),
            //            to: '/assets/withdrawals'
            //        },
                    
            //    ]
            //},
            // deposit / withdrawal / point history
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.transactionHistory'),
                route: '/history',
                icon: 'cil-puzzle',
                //roles: ['agent', 'sale', 'investor', 'supervisor'],
                items: [
                    //{
                    //    roles: ['sale', 'investor', 'supervisor', 'agent'],
                    //    name: i18n.t('pages.deposit.depositHistory'),
                    //    to: '/history/deposithistory'
                    //},
                    //{
                    //    roles: ['sale', 'investor', 'supervisor', 'agent'],
                    //    name: i18n.t('pages.withdrawal.withdrawalHistory'),
                    //    to: '/history/withdrawalhistory'
                    //},
                    //{
                    //    roles: ['sale', 'investor', 'supervisor', 'agent'],
                    //    name: i18n.t('common.historyPoints'),
                    //    to: '/history/rewardpoints'
                    //},                    
                    //{
                    //    roles: ['operator', 'sale', 'investor', 'supervisor'],
                    //    name: i18n.t('menu.buyAndSellStocks'),
                    //    to: '/shares/list'
                    //},
                    {
                        roles: ['admin', 'operator', 'investor', 'supervisor', 'sale'],
                        name: i18n.t('menu.discountsAndCommissions'),
                        to: '/history/discounts-commissions',
                    },
                    //{
                    //    roles: ['admin', 'operator', 'investor', 'supervisor'],
                    //    name: i18n.t('menu.accumulatedProfit'),
                    //    to: '/history/accumulated-profit',
                    //},
                ]
            },
            //System Settings
            {               
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.systemManagement'),
                route: '/systemsettings',
                icon: 'cil-puzzle',
                roles: ['admin'],
                items: [
                    {
                        name: i18n.t('menu.systemSettings'),                       
                        to: '/systemsettings/configs'
                    },
                    
                ]
            },

            //CMS Management
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.cmsManagement'),
                route: '/cms',
                icon: 'cil-puzzle',
                roles: ['admin'],
                items: [
                    {
                        name: i18n.t('menu.cmsListPage'),
                        to: '/cms/pages/list'
                    },
                    {
                        name: i18n.t('menu.cmsAddPage'),
                        to: '/cms/pages/add'
                    },
                    //{
                    //    name: i18n.t('menu.cmsEditPage'),
                    //    to: '/cms/pages/edit'
                    //},
                    {
                        name: i18n.t('menu.emailTemplateList'),
                        to: '/emailtemplate/list'
                    },
                ]
            },
            {
                _name: 'CSidebarNavDropdown',
                name: i18n.t('menu.generalManagement'),
                route: '/suppliers',
                icon: 'cil-puzzle',
                roles: ['admin'],
                items: [
                    {
                        name: i18n.t('menu.suppliers'),
                        to: '/suppliers/list'
                    },                   
                    {
                        name: i18n.t('menu.bankList'),
                        to: '/banks/list'
                    },
                    {
                        name: i18n.t('menu.contactList'),
                        to: '/contacts/list'
                    },
                ]
            },            
        ]
    }
]
