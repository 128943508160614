<template>
    <CDropdown inNav
               class="c-header-nav-items"
               placement="bottom-end"
               add-menu-classes="pt-0">
        <template #toggler>
            <CHeaderNavLink>
                <div class="c-avatar">
                    <img :src="imgAvatar" @error="setDefaultImg" class="c-avatar-img" />                    
                </div>
            </CHeaderNavLink>
        </template>
        <CDropdownHeader tag="div" class="text-center" color="light">
            <strong>{{ this.$t('menu.account') }}</strong>
        </CDropdownHeader>       
        <CDropdownItem to="/users/profile" v-if="loggedIn">
            <CIcon name="cil-user" />{{ this.$t('menu.profile') }}
        </CDropdownItem>      
        <!--<CDropdownItem to="/assets/deposit" v-if="isShowDepositWithdrawalItem">
            <CIcon name="cil-file" /> {{ this.$t('pages.account.deposit') }}
        </CDropdownItem>
        <CDropdownItem to="/assets/withdrawal" v-if="isShowDepositWithdrawalItem">
            <CIcon name="cil-file" /> {{ this.$t('common.withdrawal') }}
        </CDropdownItem>-->       
        <CDropdownItem to="/users/changepassword" v-if="loggedIn">
            <CIcon name="cil-shield-alt" /> {{ this.$t('pages.account.changePassword') }}
        </CDropdownItem>
        <CDropdownItem @click.native="handleLogout" v-if="loggedIn">
            <CIcon name="cil-lock-locked"> </CIcon>
            <strong>{{ this.$t('menu.logOff') }}</strong>
        </CDropdownItem>
        <CDropdownItem @click.native="handleLogin" v-else>
            <CIcon name="cil-lock-locked"> </CIcon>
            <strong>{{ this.$t('menu.logIn') }}</strong>
        </CDropdownItem>
    </CDropdown>
</template>

<script>
    import i18n from '@/plugins/i18n'
    import { mapActions, mapGetters, mapState } from 'vuex'
    export default {
        name: 'TheHeaderDropdownAccnt',
        data() {
            return {
                itemsCount: 42,
                imgAvatar: 'content/images/avatars/6.jpg'
            }
        },
        computed: {
            ...mapGetters('auth', ['loggedIn']),           

            //isShowDepositWithdrawalItem() {
            //    var currentUser = this.$store.state.auth.user;
            //    var isAuthenticated = this.$store.state.auth.status.loggedIn;
            //    if (!isAuthenticated)
            //        return false;
            //    if (currentUser.role === 'admin' || currentUser.role === 'agent')
            //        return false;               

            //    return true;
            //},            
        },
        methods: {
            ...mapActions('auth', ['logout']),
            ...mapActions('profile', ['getAvatarLink']),

            async handleLogout(e) {
                await this.logout();
                this.$router.push("/pages/login");
            },
            async handleLogin(e) {
                this.$router.push("/pages/login");
            },
            setDefaultImg(event) {
                event.target.src = "content/images/avatars/6.jpg"
            },            
        },
        async created() {            
            var req = await this.getAvatarLink(parseInt(this.$store.state.auth.user.sub));
            if (req != null && req.imageUrl != '')
                this.imgAvatar = req.imageUrl;
        }
    }
</script>

<style scoped>
    .c-icon {
        margin-right: 0.3rem;
    }
</style>