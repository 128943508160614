<template>
    <CSidebar fixed
              :minimize="minimize"
              :show="show"
              @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
        <CSidebarBrand class="d-md-down-none" to="/">
            <CIcon class="c-sidebar-brand-full"
                   name="logo"
                   size="custom-size"
                   :height="45"
                   viewBox="0 0 556 134" src="content/images/logo.png" />
            <CIcon class="c-sidebar-brand-minimized"
                   name="logo"
                   size="custom-size"
                   :height="35"
                   viewBox="0 0 110 134"
                   src="content/images/logo.png" />
        </CSidebarBrand>

        <!--<CRenderFunction flat :content-to-render="$options.nav" />-->
        
        <CRenderFunction flat :content-to-render="navComputed" />
        <CSidebarMinimizer class="d-md-down-none"
                           @click.native="$store.commit('set', ['sidebarMinimize', !minimize])" />
    </CSidebar>
</template>

<script>
    import nav from './_nav'
    import { mapGetters, mapState } from 'vuex'

    export default {
        name: 'TheSidebar',
        nav,
        data() {
            return {
                role: 'admin',
                flat: true
            }
        },
        computed: {
            ...mapState('auth', ['user']),
            ...mapGetters('auth', ['loggedIn', 'profile']),
            show() {
                return this.$store.state.sidebarShow
            },
            minimize() {
                return this.$store.state.sidebarMinimize
            }
            ,
            navComputed() {
                var currentUser = this.$store.state.auth.user;
                var isAuthenticated = this.$store.state.auth.status.loggedIn;
                var newRoot = [];
                var currentRole = '';
                if (isAuthenticated && currentUser != null) {
                    currentRole = currentUser.role;
                }
                this.traverseTree(nav, newRoot, currentRole);
                
                return newRoot;
            }
        },
        methods: {
            traverseTree(tree, newRoot, currentRole) {
                for (let i = 0; i < tree.length; i++) {
                    var navItem = tree[i];

                    var show = false;
                    if (navItem.roles) {
                        if (navItem.roles.includes(currentRole)) {
                            show = true;
                        }
                    }
                    else {
                        show = true;
                    }
                    if (!show) {
                        continue;
                    }
                    var treeNode = {};

                    if (navItem._name) treeNode._name = navItem._name;
                    if (navItem.name) treeNode.name = navItem.name;
                    if (navItem.to) treeNode.to = navItem.to;
                    if (navItem.icon) treeNode.icon = navItem.icon;
                    if (navItem.route) treeNode.route = navItem.route;
                    if (navItem.badge) treeNode.badge = navItem.badge;

                    newRoot.push(treeNode);

                    if (navItem._children && navItem._children.length > 0) {
                        treeNode._children = [];
                        this.traverseTree(navItem._children, treeNode._children, currentRole);
                    }
                    if (navItem.items && navItem.items.length > 0) {
                        treeNode.items = [];
                        this.traverseTree(navItem.items, treeNode.items, currentRole);
                    }
                }
            }
        }
    }
</script>
