<template>
    <CFooter :fixed="false">
        <div>
            <a href="https://thsoft.net" target="_blank">DTH</a>
            <span class="ml-1">&copy; {{new Date().getFullYear()}} creativeLabs.</span>
        </div>
        <div class="mfs-auto">
            <span class="mr-1" target="_blank">Powered by</span>
            <a href="https://thsoft.net">DTH Limited Co.</a>
        </div>
    </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
